<template lang="pug">
modal-card(:title='titleModal' width='500px')
  app-form.zone-form(@submit.prevent='submit' :is-saving='saving' autocomplete='off')
    text-field(label='Nombre' placeholder='Ejm: Área metropolitana' :maxlength='30' required v-model='zone.name')
    color-field(label='Color' required v-model='zone.backgroundColor')
</template>

<script>
import toastService from '@/services/toast.service'

export default {
  props: {
    latLngs: { type: Array, default: () => [] },
    zoneUpdated: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      saving: false,
      zone: {
        backgroundColor: '#0065FF7F',
        name: ''
      }
    }
  },
  mounted () {
    this.zone.backgroundColor = this.zoneUpdated?.backgroundColor || '#0065FF7F'
    this.zone.name = this.zoneUpdated?.name || ''
  },
  computed: {
    titleModal () {
      return this.zoneUpdated?.id ? 'Actualizar zona' : 'Crear una zona'
    }
  },
  methods: {
    async submit () {
      const latLngs = []
      this.latLngs.forEach(latLng => {
        latLngs.push([latLng.lat, latLng.lng])
      })
      const zone = {
        ...this.zoneUpdated,
        ...this.zone,
        latLngs: this.latLngs.length ? latLngs : this.zoneUpdated.latLngs
      }
      this.saving = true
      const result = await this.$store.dispatch('zones/saveZone', zone)
      if (result) {
        toastService.show('Zona guardada')
        this.$parent.close()
      }
      this.saving = false
    }
  }
}
</script>

<style lang="scss" scoped>
.zone-form {
  min-height: 350px;
}
</style>
